import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import ThemeProvider from '../providers/themeProvider'
import GlobalStyleProvider from '../providers/globalStyleProvider'

const Layout = ({ children, style = {} }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider>
      <GlobalStyleProvider />
      <Header siteTitle={data.site.siteMetadata.title} />

      <div style={{ overflowX: 'hidden', ...style }}>{children}</div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
