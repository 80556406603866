import { css } from 'styled-components'

const fontFamilyInter = `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif`
const fontFamilyGT = `GT America Extended Black, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif`
const fontFamilyGTBold = `GT America Bold, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif`
const fontFamilyGTMono = `GT America Mono, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif`

const fontFamilySuisseIntl = `Suisse Intl, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif`

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

export const typography = {
  fontFamilyInter,
  fontFamilyGT,
  fontFamilyGTBold,
  fontFamilyGTMono,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  fontFamilySuisseIntl,
}

export const textType = {
  'display-xSmall': css`
    font-size: 2.5rem;
    line-height: 3.25rem;
  `,
  'display-small': css`
    font-size: 2.75rem;
    line-height: 3.5rem;
  `,
  'display-medium': css`
    font-size: 3.25rem;
    line-height: 4rem;
  `,
  'display-large': css`
    font-size: 3.75rem;
    line-height: 4.25rem;
  `,
  'heading-xSmall': css`
    font-size: 1.125rem;
    line-height: 1.75rem;
  `,
  'heading-small': css`
    font-size: 1.25rem;
    line-height: 1.75rem;
  `,
  'heading-medium': css`
    font-size: 1.5rem;
    line-height: 2rem;
  `,
  'heading-large': css`
    font-size: 1.75rem;
    line-height: 2.25rem;
  `,
  'heading-xLarge': css`
    font-size: 2rem;
    line-height: 2.5rem;
  `,
  'heading-xxLarge': css`
    font-size: 2.25rem;
    line-height: 2.75rem;
  `,
  'label-xSmall': css`
    font-size: 0.75rem;
    line-height: 1.25rem;
  `,
  'label-small': css`
    font-size: 0.875rem;
    line-height: 1.25rem;
  `,
  'label-medium': css`
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  'label-large': css`
    font-size: 1.125rem;
    line-height: 1.75rem;
  `,
  'label-xLarge': css`
    font-size: 1.25rem;
    line-height: 1.75rem;
  `,
  'label-xxLarge': css`
    font-size: 1.5rem;
    line-height: 2rem;
  `,
  'paragraph-xSmall': css`
    font-size: 0.75rem;
    line-height: 1.25rem;
  `,
  'paragraph-small': css`
    font-size: 0.875rem;
    line-height: 1.25rem;
  `,
  'paragraph-medium': css`
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  'paragraph-large': css`
    font-size: 1.125rem;
    line-height: 1.75rem;
  `,
  'paragraph-xLarge': css`
    font-size: 1.25rem;
    line-height: 1.75rem;
  `,
  'paragraph-xxLarge': css`
    font-size: 1.5rem;
    line-height: 2rem;
  `,
}

export default { typography, textType }
