export const colors = {
  purple: {
    50: '#FBF5FF',
    100: '#EACCFF',
    200: '#D9A3FF',
    300: '#B555F8',
    400: '#9601FF',
    500: '#7205BE',
    600: '#590992',
    700: '#3D0C60',
  },
  blue: {
    50: '#EEF7FB',
    100: '#C4E6F9',
    200: '#93D3F4',
    300: '#66C1F2',
    400: '#42ADE7',
    500: '#2596D4',
    600: '#2679A6',
    700: '#205572',
  },
  green: {
    50: '#E7F8EE',
    100: '#CFF2DE',
    200: '#AFE9C8',
    300: '#88DAAC',
    400: '#55CF8A',
    500: '#41AF71',
    600: '#3F9464',
    700: '#366A4C',
  },
  yellow: {
    50: '#FDF6DD',
    100: '#FCEEC0',
    200: '#FAE6A3',
    300: '#F9E08C',
    400: '#F8D96D',
    500: '#EFC841',
    600: '#D4AC20',
    700: '#A48417',
  },
  orange: {
    50: '#FDF3EE',
    100: '#FBE2D6',
    200: '#F7BFA5',
    300: '#F19063',
    400: '#ED6E33',
    500: '#B45326',
    600: '#8E421E',
    700: '#5F2C14',
  },
  red: {
    50: '#FBEFEE',
    100: '#FAD6D7',
    200: '#F0898C',
    300: '#EC666A',
    400: '#E73C41',
    500: '#C02328',
    600: '#942528',
    700: '#6E2527',
  },
  turquoise: {
    50: '#EDFCFC',
    100: '#D8F8F9',
    200: '#B0F3F4',
    300: '#79EDF0',
    400: '#2FE8ED',
    500: '#1AD3D8',
    600: '#1FBABF',
    700: '#22A0A3',
  },
  gray: {
    50: '#F6F6F6',
    100: '#EEEEEE',
    200: '#E2E2E2',
    300: '#CBCBCB',
    400: '#AFAFAF',
    500: '#757575',
    600: '#545454',
    700: '#333333',
    800: '#1F1F1F',
    900: '#141414',
  },
  white: '#FFFFFF',
  black: '#000000',
}

export const foundation = {
  primaryA: colors.black,
  primaryB: colors.white,
  accent: colors.blue[400],
  negative: colors.red[400],
  warning: colors.yellow[400],
  positive: colors.green[400],
}

export const core = {
  background: {
    primary: foundation.primaryB,
    secondary: colors.gray[50],
    tertiary: colors.gray[100],
    inversePrimary: foundation.primaryA,
    inverseSecondary: colors.gray[800],
    stateDisabled: colors.gray[50],
    overlayDark: 'rgba(0, 0, 0, 0.3)',
    overlayLight: 'rgba(0, 0, 0, 0.08)',
    accent: foundation.accent,
    negative: foundation.negative,
    warning: foundation.warning,
    positive: foundation.positive,
    lightAccent: colors.blue[50],
    lightPositive: colors.green[50],
    lightNegative: colors.red[50],
    lightWarning: colors.yellow[50],
    alwaysDark: colors.black,
    alwaysLight: colors.white,
    turquoise: colors.turquoise[400],
  },
  content: {
    primary: foundation.primaryA,
    secondary: colors.gray[600],
    tertiary: colors.gray[500],
    inversePrimary: foundation.primaryB,
    inverseSecondary: colors.gray[300],
    inverseTertiary: colors.gray[400],
    stateDisabled: colors.gray[400],
    accent: colors.blue[400],
    onColor: colors.white,
    negative: colors.red[400],
    warning: colors.yellow[500],
    positive: colors.green[400],
  },
  border: {
    opaque: colors.gray[200],
    transparent: 'rgba(0, 0, 0, 0.08)',
    selected: foundation.primaryA,
    inverseOpaque: colors.gray[700],
    inverseTransparent: 'rgba(255, 255, 255, 0.2)',
    inverseSelected: foundation.primaryB,
    stateDisabled: colors.gray[50],
    accent: colors.blue[400],
    accentLight: colors.blue[200],
    negative: colors.red[200],
    warning: colors.yellow[200],
    positive: colors.green[200],
  },
}

export default { colors, foundation, core }
