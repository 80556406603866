export const elevations = {
  above: {
    high: '0px -16px 48px rgba(0, 0, 0, 0.22)',
    medium: '0px -8px 36px rgba(0, 0, 0, 0.17)',
    low: '0px -4px 16px rgba(0, 0, 0, 0.12)',
  },
  below: {
    low: '0px 4px 16px rgba(0, 0, 0, 0.12)',
    medium: '0px 8px 36px rgba(0, 0, 0, 0.17)',
    high: '0px 16px 48px rgba(0, 0, 0, 0.22)',
  },
}

export const shadows = [
  'none',
  elevations.above.high,
  elevations.below.low,
  elevations.below.medium,
  elevations.below.high,
  elevations.above.low,
  elevations.above.medium,
]

export default { elevations, shadows }
