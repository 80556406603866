import { createGlobalStyle } from 'styled-components'
import '../assets/fonts/inter.css'
import '../assets/fonts/gt-america.css'
import '../assets/fonts/suisse-intl.css'
import '../assets/styles/index.css'
import { colors, core } from '../theme/colors'
import { typography } from '../theme/typography'
import device from '../theme/device'

const { fontFamilyInter, fontFamilyGTBold, fontFamilySuisseIntl } = typography

const GlobalStyle = createGlobalStyle`
  html {
    padding: 0;
    margin: 0;
    font-size: 14px;

    @media ${device.tablet} {
      font-size: 16px;
    }

    @media ${device.laptopL} {
      font-size: 18px;
    }

    @media (min-width: 1680px) {
      font-size: 20px;
    }
  }

  body {
    padding: 0;
    margin: 0;
    background-color: white;
    font-family: ${fontFamilyInter};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${core.content.inversePrimary};
    transition: all 100ms ease-in-out;
    box-sizing: border-box;

    :hover, :focus {
      opacity: 0.8;
    }
  }

  p {
    margin: 0px;
  }

  h1  {
    font-family: ${fontFamilySuisseIntl};
    color: ${colors.primary};
    font-weight: 500;
    font-size: 35.2px;
    line-height: 52px;
  }

  h2  {
    font-family: ${fontFamilySuisseIntl};
    color: ${colors.primaryA};
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
  }

  h3 {
    font-family: ${fontFamilySuisseIntl};
    color: ${colors.primaryA};
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
  }

  h4 {
    font-family: ${fontFamilySuisseIntl};
    color: ${colors.primary};
    font-weight: 500;
    font-size: 20.8px;
    line-height: 36px;
  }

  h5: {
    font-family: ${fontFamilySuisseIntl};
    color: ${colors.primaryA};
    font-weight: 500;
    font-size: 20.8px;
    line-height: 32px;
  }

  h6: {
    font-family: ${fontFamilySuisseIntl};
    color: ${colors.primaryA};
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
`

export default GlobalStyle
