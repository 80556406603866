import { colors, foundation, core } from './colors'
import { elevations, shadows } from './shadows'
import { typography, textType } from './typography'
import device from './device'
import spacing from './spacing'
import zIndex from './zIndex'

export default {
  colors,
  foundation,
  core,
  elevations,
  shadows,
  typography,
  textType,
  spacing,
  device,
  zIndex,
}
